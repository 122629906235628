<template>
  <div :class="rendererClass" v-on="$listeners">
    <selection-bound :items="selectionRows" v-show="!isEmpty" />
    <div class="paper">
      <slot name="header"></slot>
      <div v-if="isEmpty">
        <slot name="empty" />
      </div>
      <part v-show="!isEmpty" v-slot="{ part }" :parts="parts">
        <div class="row" v-for="(row, rowIndex) in part" :key="rowIndex">
          <span
            :class="bloc.class"
            :ref="`bloc-${bloc.eventIndex}`"
            :id="`bloc-${bloc.eventIndex}`"
            v-for="(bloc, blocIndex) in row"
            :key="blocIndex"
            @click="selectFromBloc(bloc, $event)"
          >
            <!-- CHORDS -->

            <chord
              :id="`chord-${bloc.eventIndex}`"
              v-if="report.showChord"
              :class="bloc.chord.class"
              :raw="bloc.chord.raw"
              :transpose="transpose"
            />

            <!-- METRIC -->

            <div class="metric" v-if="report.showMetric"></div>
            <!-- LYRICS -->

            <div class="lyrics" v-if="report.showLyric">
              <div
                :class="lyric.class"
                :id="`lyric-${bloc.eventIndex}-${lyricIndex}`"
                v-for="(lyric, lyricIndex) in bloc.lyrics"
                :key="lyricIndex"
              >
                <span
                  v-if="lyric.anchorLeft"
                  :class="lyric.anchorLeft.class"
                ></span>
                <div class="raw">
                  <span>{{ lyric.raw }}</span>
                </div>
                <span
                  v-if="lyric.anchorRight"
                  :class="lyric.anchorRight.class"
                ></span>
              </div>
            </div>
          </span>
        </div>
      </part>
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
import rendererMixin from '../../mixins/renderer-mixin'
import selectionMixin from '../../mixins/selection-mixin'

import lyricBuilder from '../../processors/lyric-builder'

import Part from '../misc/Part.vue'
import Chord from '../misc/Chord.vue'
import SelectionBound from '../misc/SelectionBound.vue'

export default {
  name: 'LyricsRenderer',
  mixins: [rendererMixin, selectionMixin],
  components: { Part, Chord, SelectionBound },
  props: {
    locked: Boolean
  },
  data: () => ({
    events: null,
    parts: null,
    report: null,
    selecting: false,
    transpose: 0,
    isEmpty: true
  }),
  computed: {
    rendererClass() {
      const cls = ['lyric-renderer']
      if (this.locked) cls.push('locked')
      return cls
    }
  },
  destroyed() {
    this.parts = null
    this.report = null
  },
  methods: {
    render({ events, flows, settings, updates, report }) {
      //
      // LYRIC BUILDER
      //
      if (updates.has('events', 'renderOptions', 'renderer')) {
        const renderOptions = settings.user.renderOptions
        const chars = settings.chars
        const { parts, eventMap } = lyricBuilder({
          events,
          flows,
          chars,
          renderOptions,
          report
        })
        this._settings = settings
        this._blocMap = eventMap
        this.report = report
        this.parts = () => parts
        report.blocMap = () => eventMap
        console.log('LYRIC BUILDER', { parts, eventMap })
        this.isEmpty = !report.hasLyric && !report.hasHarmony
      }
      this.transpose = settings.user.transpose
    },

    getSelectionElements(selection) {
      if (selection.isLine) {
        const blocs = this._blocMap.get(selection.start)[0].row
        return blocs.map((bloc) => this.getElement(bloc.eventIndex))
      }
    },

    selectFromBloc(bloc, event) {
      if (this.locked) return
      //
      // Look for the node where the click come from
      //
      const node = event.path.find((n) => {
        if (!n.id) return
        const isLyric = n.id.startsWith('lyric')
        const isChord = n.id.startsWith('chord')
        const isBloc = n.id.startsWith('bloc')
        return isLyric || isChord || isBloc
      })
      if (node) {
        const nodeMatch = node.id.match(/(\w+)-(\d+)-?(\d+)?/)
        let type = nodeMatch[1]
        let lyricIndex = Number(nodeMatch[3])
        //
        // If bloc is selected, We have to choose between chord and lyric for
        // rawPos
        //
        let rawPos
        if (type === 'bloc' && bloc.lyrics.length) {
          rawPos = bloc.lyrics[0].rawPos
        } else if (type === 'chord' || type === 'bloc') {
          rawPos = bloc.chord.rawPos
        } else {
          rawPos = bloc.lyrics[lyricIndex].rawPos
        }
        //
        // Do the selection
        //
        this.select(bloc, rawPos)
      }
    }
  }
}
</script>

<style scoped>
.renderer .row {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  color: currentColor;
  margin: 0;
}
.renderer .row:not(:last-child) {
  /* margin-bottom: var(--sheet-line-gutter); */
}

/* BLOCS */

.bloc {
  position: relative;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  user-select: none;
  justify-content: space-between;
  padding: calc(var(--sheet-line-gutter) / 2) 0;
}
.lyric-renderer:not(.locked) .bloc {
  cursor: pointer;
}
/* .bloc.empty {
  display: none;
} */

.bloc:not(.anchor-right) {
  padding-right: calc(var(--sheet-bloc-spacer) / 2);
}
.bloc:not(.anchor-left) {
  padding-left: calc(var(--sheet-bloc-spacer) / 2);
}

/* METRIC */

.metric {
  position: relative;
  min-height: 8px;
  /* margin-bottom: var(--sheet-bloc-gutter); */
}

.metric:after {
  content: ' ';
  position: absolute;
  min-height: 50%;
  left: calc(50% - 1px);
  width: 1px;
  top: 4px;
  border-radius: 2px;
}

.on-beat .metric:after {
  background: var(--sheet-color-metric);
  opacity: 0.5;
}
.on-measure .metric:after {
  background: var(--sheet-color-metric);
  /* opacity: 1; */
  width: 2px;
}

/* CHORD */

.chord {
  /* margin-top: 4px; */
  margin-bottom: 1px;
  align-self: center;
}
.chord:before {
  /* top: 1px; */
  height: calc(100% + 1px);
}

/* LYRICS */

.lyric {
  display: flex;
  justify-content: center;
  font-size: var(--sheet-lyric-font-size);
  font-weight: var(--sheet-lyric-weight);
  font-family: var(--sheet-lyric-font-family);
  position: relative;
}
.lyric.start {
  text-transform: capitalize;
}
.lyric:not(.first) {
  font-style: italic;
  opacity: 0.5;
}

.bloc.has-chord .lyric:not(.empty) {
  font-weight: bold;
}

/* .lyric.empty,
 {
  color: #999999;
  min-width: 12px;
} */

.raw {
  position: relative;
}
.raw > * {
  position: relative;
}

/* ANCHORS */

.anchor {
  flex: 1;
  height: 0px;
}
.anchor:not(.hidden) {
  background: #bbbbbb;
  margin-top: 11px;
}
.anchor.left:not(.hidden) {
  margin-right: calc(var(--sheet-bloc-spacer) / 2);
}
.anchor.right:not(.hidden) {
  margin-left: calc(var(--sheet-bloc-spacer) / 2);
}
.anchor:not(.hidden) {
  height: 1px;
  min-width: var(--sheet-bloc-spacer);
}

/* SELECT RAW */

.lyric:hover .raw:before {
  position: absolute;
  left: -2px;
  top: 0;
  width: calc(100% + 4px);
  height: calc(100% + 0px);
  content: ' ';
  border-radius: 2px;
}

/* SELECTED EVENT */

.bloc.head .lyric {
  text-decoration: underline;
}

.bloc.selected,
.bloc.selected .lyric.forced {
  color: inherit;
}

.bloc.selected .chord:before {
  background: var(--sheet-selection-color);
  opacity: 0.3;
}
</style>
