import EventMap from '../models/data/EventMap'

class Part extends Array {}
class Row extends Array {}
class Bloc {}
class Lyric {}
class Chord {}
class Anchor {}

const hiddenPartType = ['intro', 'default']

export default ({ events, flows, chars, renderOptions, report }) => {
  let parts = []
  const rows = []
  const blocs = []

  if (!report.hasLyric && !report.hasHarmony) {
    return { parts, rows, blocs, eventMap: new EventMap() }
  }

  report.showMetric = renderOptions.indexOf('metric') > -1 && report.hasMetric
  report.showLyric = renderOptions.indexOf('lyric') > -1 && report.hasLyric
  report.showChord = renderOptions.indexOf('harmony') > -1 && report.hasHarmony

  let currentPart, currentRow

  const lyricFlows = flows.filter((flow) => {
    return flow.context.name === 'lyric'
  }, 0)
  //
  // STEP 1 : Build blocs ======================================================
  //
  events.forEach((event, eventIndex) => {
    let bloc, isEndOfRow, lastBlocInRow
    event.forEach((context) => {
      const cname = context.name
      const isPart = cname === 'part' && context.raw != chars.empty
      const isHarmony = cname === 'chord' /*&& template.harmony*/
      const isLyric = cname === 'lyric'
      if (isPart) {
        //
        // Part creation. Note that part will be overwrited later then some
        // other properties of the part will be setted at this moment
        //
        currentPart = new Part()
        currentPart.type = context.raw
        currentRow = null
        parts.push(currentPart)
      }
      if (isHarmony || isLyric) {
        //
        // Create current part (if required)
        //
        if (!currentPart) {
          currentPart = new Part()
          currentPart.type = 'default'
          parts.push(currentPart)
        }
        //
        // Create current row (if required)
        //
        if (!currentRow) {
          currentRow = new Row()
          currentRow.isEmpty = []
          currentRow.hasChord = false
          currentRow.hasLyric = false
          bloc = lastBlocInRow = null
          currentPart.push(currentRow)
        }
        //
        // Create current bloc
        //
        if (!bloc) {
          bloc = new Bloc()
          bloc.hasChord = false
          bloc.hasLyric = false
          bloc.events = [event]
          bloc.eventIndex = eventIndex
          bloc.class = ['bloc']
          bloc.lyrics = []
          bloc.start = event.position
          lastBlocInRow = currentRow[currentRow.length - 1]
          currentRow.push(bloc)
          if (event.onBeat) bloc.class.push('on-beat')
          if (event.onFragment) bloc.class.push('on-measure')
        }
      }
      if (isHarmony && report.showChord) {
        //
        // Chords
        //
        const chord = new Chord()
        chord.raw = context.raw
        chord.rawPos = context.rawPos
        chord.class = ['chord']
        const chordIsEmpty = context.raw === chars.empty
        const forceChord = !report.showLyric && report.showMetric
        if (!chordIsEmpty || forceChord) {
          bloc.class.push('has-chord')
          bloc.hasChord = true
          if (forceChord && chordIsEmpty) {
            chord.raw = '•'
            chord.class.push('forced')
          }
        } else {
          chord.class.push('empty')
        }
        currentRow.hasChord = currentRow.hasChord || !chordIsEmpty
        bloc.chord = chord
      }
      if (isLyric) {
        //
        // Lyrics. Note: even if we don't have to show lyric, we evalutate
        // lyric to break row
        //
        const flow = flows[context.flowIndex]
        const lyricIndex = lyricFlows.indexOf(flow)
        const lastLyricAtIndex = lastBlocInRow?.lyrics[lyricIndex]
        const lyric = new Lyric()
        lyric.class = ['lyric']
        lyric.rawPos = context.rawPos
        let raw = context.raw
        //
        // Remove paren content
        //
        raw = raw.replace(/\(([^)]+)\)/g, '')
        //
        // Check end of row
        //
        if (!bloc.lyrics.length && context.breakline) {
          isEndOfRow = true
        }
        if (!report.showLyric) {
          raw = chars.empty
        }
        if (lastLyricAtIndex?.isAnchored && raw === '-') {
          raw = '~'
        }
        if (raw === '~') raw = '-~'
        if (raw === '·') raw = '-'
        //
        // Check the anchor
        //
        const lastChar = raw.charAt(raw.length - 1)
        lyric.isAnchored = (lastChar === '~' || lastChar === '_') && raw != '·'
        if (lyric.isAnchored) {
          raw = raw.slice(0, -1)
        }
        //
        // Check the emptyness
        //
        if (currentRow.isEmpty.length === lyricIndex) {
          currentRow.isEmpty[lyricIndex] = true
        }
        const lyricIsEmpty = raw === chars.empty
        if (lyricIsEmpty && (!report.showMetric || !report.showChord)) {
          lyric.class.push('empty')
        }
        //
        // Set the raw if the lyric is empty. The raw lyric can be "forced" and
        // will be '•'
        //
        if (lyricIsEmpty) {
          const isForced = report.showMetric || bloc.hasChord
          if (isForced) {
            lyric.class.push('forced')
            raw = '•'
          } else {
            raw = ''
          }
        }
        //
        // Check if the lyric is at the start of the row
        //
        if (currentRow.isEmpty[lyricIndex] && !lyricIsEmpty) {
          currentRow.isEmpty[lyricIndex] = false
          lyric.class.push('start')
        }
        //
        // Check if the lyric is the first of the bloc
        //
        if (!lyricIndex) {
          lyric.class.push('first')
        }
        //
        // Add the lyric to the current bloc
        //
        lyric.raw = raw
        lyric.isEmpty = lyricIsEmpty
        bloc.lyrics[lyricIndex] = lyric
        if (!lyricIsEmpty) bloc.hasLyric = true
        currentRow.hasLyric = currentRow.hasLyric || !lyricIsEmpty
      }
    })

    //
    // Adding empty lyric if the bloc doesn't have it
    //
    if (bloc) {
      lyricFlows.forEach((flow, index) => {
        if (!bloc.lyrics[index]) {
          const emptyLyric = new Lyric()
          emptyLyric.raw = chars.empty
          emptyLyric.isEmpty = true
          emptyLyric.class = ['lyric', 'empty']
          bloc.lyrics[index] = emptyLyric
        }
      })
    }
    //
    // Adding empty chord if the bloc contains no information about chord
    // That wil happens in the second event of the example below
    // ^ |  A        |
    // @ | [hel~ lo] |
    //
    if (bloc) {
      if (!bloc.chord) {
        const emptyChord = new Chord()
        emptyChord.class = ['empty', 'chord']
        emptyChord.raw = chars.empty
        bloc.chord = emptyChord
      }
    }
    //
    // Check if the current bloc should be merged with the last (see step 2)
    //
    if (bloc) {
      //
      // A bloc with no lyric and no chord should be merged
      //
      if (!bloc.hasLyric && !bloc.hasChord) {
        bloc.shouldBeMerged = true
      }
      //
      // A bloc should be merged if
      // - There is no metric,
      // - Both of the bloc and lastBloc have no chord
      // - Both of the bloc and lastBloc have lyric
      // - All lyrics of lastBloc are anchored
      if (!report.showMetric && !bloc.shouldBeMerged && lastBlocInRow) {
        const chordOk = !lastBlocInRow.hasChord && !bloc.hasChord
        if (chordOk) {
          bloc.shouldBeMerged = lastBlocInRow.lyrics.every(
            (lyric) => lyric.isAnchored
          )
        }
      }
    }
    //
    // Clear the current row (because end of row)
    //
    if (isEndOfRow) {
      currentRow = null
    }
  })
  //
  // STEP 2 : Merging blocs ====================================================
  //
  const mergeBloc = (bloc, target, pendingIndex) => {
    //
    // Merge events and add to merged
    //
    target.events.push(...bloc.events)
    if (!target.merged) {
      target.merged = [bloc]
    } else {
      target.merged.push(bloc)
    }
    //
    // Merge lyrics and rawPos
    //
    if (isNaN(pendingIndex)) {
      bloc.lyrics.forEach((lyric, lyricIndex) => {
        const targetLyric = target.lyrics[lyricIndex]
        if (!lyric.isEmpty) {
          targetLyric.raw += lyric.raw
        }
        targetLyric.isAnchored = lyric.isAnchored
        if (lyric.rawPos) {
          const rawPosEnd = lyric.rawPos.pos + lyric.rawPos.length
          targetLyric.rawPos.length = rawPosEnd - targetLyric.rawPos.pos
        }
      })
    }
    //
    // Update start position if the merged bloc is the first of pendings
    //
    if (pendingIndex === 0) {
      target.start = bloc.start
    }
  }
  let lastBlocIncluded
  parts = parts.map((part) => {
    const filteredPart = part.map((row) => {
      const pendings = []
      const filteredRow = row.filter((bloc) => {
        if (bloc.shouldBeMerged) {
          if (!lastBlocIncluded) {
            //
            // If this is the first bloc, no bloc has been included. So the
            // bloc is pending until one is accepted
            //
            pendings.push(bloc)
          } else {
            mergeBloc(bloc, lastBlocIncluded)
          }
        } else {
          //
          // When the bloc is accepted
          //
          if (pendings.length) {
            //
            // Pendings contains bloc which should be merged but they was at the
            // begining of a row and then didn't have any bloc to be merged
            // with. Now we can.
            //
            pendings.forEach((pendingBloc, pendingIndex) => {
              mergeBloc(pendingBloc, bloc, pendingIndex)
            })
            pendings.length = 0
          }
          if (lastBlocIncluded) {
            //
            // The end of the last bloc, is the start of the current one
            //
            lastBlocIncluded.end = bloc.start
          }
          lastBlocIncluded = bloc
        }
        return !bloc.shouldBeMerged
      })
      filteredRow.hasLyric = row.hasLyric
      filteredRow.hasChord = row.hasChord
      return filteredRow
    })
    //
    // As we map the result, the part property should be copied
    //
    const partType = part.type
    const optionTitle = renderOptions.indexOf('part') > -1
    const hiddenTitle = hiddenPartType.indexOf(partType) > -1
    filteredPart.type = partType
    if (optionTitle && !hiddenTitle) {
      filteredPart.showTitle = true
      filteredPart.title = partType
    }
    return filteredPart
  })
  if (lastBlocIncluded) {
    lastBlocIncluded.end = report.duration
  }
  //
  // Create right anchors ======================================================
  //

  parts.forEach((part) => {
    part.forEach((row) => {
      row.forEach((bloc, blocIndex) => {
        //
        //
        //
        if (!bloc.hasChord && !bloc.hasLyric) {
          bloc.class.push('empty')
        }

        if (report.showLyric) {
          const nextBloc = row[blocIndex + 1]
          bloc.lyrics.forEach((lyric, lyricIndex) => {
            //
            // Set the right anchor of the lyric
            //
            if (lyric.isAnchored && nextBloc) {
              lyric.anchorRight = new Anchor()
              lyric.anchorRight.class = ['anchor', 'right']
              bloc.anchorRight = true
              if (bloc.class.indexOf('anchor-right') === -1) {
                bloc.class.push('anchor-right')
              }
            }
            if (lyric.anchorLeft && !lyric.anchorRight) {
              lyric.anchorRight = new Anchor()
              lyric.anchorRight.class = ['anchor', 'right', 'hidden']
            }
            //
            // Set the left anchor of the next lyric (at same index)
            //
            if (nextBloc) {
              const nextLyric = nextBloc.lyrics[lyricIndex]
              nextLyric.anchorLeft = new Anchor()
              if (lyric.isAnchored) {
                nextLyric.anchorLeft.class = ['anchor', 'left']
                nextBloc.anchorLeft = true
                if (nextBloc.class.indexOf('anchor-left') === -1) {
                  nextBloc.class.push('anchor-left')
                }
              } else {
                nextLyric.anchorLeft = new Anchor()
                nextLyric.anchorLeft.class = ['anchor', 'left', 'hidden']
              }
            }
          })
        }
      })
    })
  })

  //
  //
  // STEP 3 : Build rows and blocs =============================================
  //
  const eventMap = new EventMap()
  let lastBloc
  let eventIndex = 0
  parts.forEach((part, partIndex) => {
    part.forEach((row, rowIndex) => {
      row.forEach((bloc, blocIndex) => {
        //
        // Update indexes
        //
        bloc.index = blocs.length
        blocs.push(bloc)
        bloc.indexInRow = blocIndex
        bloc.partIndex = partIndex
        bloc.rowIndex = rows.length
        bloc.row = row
        //
        // Update eventMap()
        //
        bloc.events.sort((a, b) => a.index - b.index)
        bloc.events.forEach((event) => {
          //
          // Some event can be not linked to a bloc.
          //
          if (eventIndex < event.index) {
            eventMap.fill(eventIndex, event.index, lastBloc || bloc)
          }
          eventMap.set(event.index, bloc)
          eventIndex = event.index
          lastBloc = bloc
        })
        bloc.events = new Map(bloc.events.map((e) => [e.index, e]))
      })
      row.index = rows.length
      row.indexInPart = rowIndex
      rows.push(row)
    })
  })

  if (events.length != eventMap.size) {
    throw new Error('WTF : events count and eventMap must be equal')
  }

  return { parts, rows, blocs, eventMap }
}
